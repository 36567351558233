import React, { useState, useEffect } from "react";
import RemnantForm from "./RemnantForm";
import { Paper, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import RemnantService from "../services/remnant.service"
import Controls from "../components/controls/Controls";
import AddIcon from '@mui/icons-material/Add';
import Popup from "../components/Popup";
import { config } from '../services/config'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import UploadIcon from '@mui/icons-material/UploadOutlined';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import RemnantImage from "./RemnantImage";

const StyledPaper = styled(Paper)(({ theme }) => ({
    margin: theme.spacing(1),
    padding: theme.spacing(1)
}));
const AddNewButton = styled(Controls.Button)({
    position: 'absolute',
    right: '10px'
});
function getStockNumber(params) {
    return "R" + `${params.row.id + 1000} `;
}

export default function Remnants() {
    const [selectedId, setSelectedId] = useState(null);
    const [recordForEdit, setRecordForEdit] = useState(null)
    const [records, setRecords] = useState([])
    const [materialNames, setMaterialNames] = useState([])
    const [openPopup, setOpenPopup] = useState(false)
    const [openImagePopup, setOpenImagePopup] = useState(false)
    const [imageUrl, setImageUrl] = useState([])
    useEffect(() => {
        retrieveRemnants();
        retrieveMaterialNames();
    }, []);

    const retrieveMaterialNames = () => {
        RemnantService.listMaterialNames()
            .then(response => {
                setMaterialNames(response.data);
            })
            .catch(e => {
                alert(e)
            });
    };
    const retrieveRemnants = () => {
        RemnantService.list()
            .then(response => {
                setRecords(response.data);
            })
            .catch(e => {
                alert(e)
            });
    };
    const updateRemnant = (remnant) => {
        RemnantService.update(remnant)
            .then(response => {
                retrieveRemnants();
            })
            .catch(e => {
                alert(e)
            });
    };

    const createRemnant = (remnant) => {
        RemnantService.create(remnant)
            .then(response => {
                retrieveRemnants();
            })
            .catch(e => {
                alert(e);
            });
    };

    const addOrEdit = (remnant, resetForm) => {
        if (remnant.id === 0)
            createRemnant(remnant);
        else
            updateRemnant(remnant);
        resetForm()
        setRecordForEdit(null)
        setOpenPopup(false)
        retrieveRemnants()
    }

    const openInPopup = id => {
        const editedRow = records.find((record) => record.id === id);
        setRecordForEdit(editedRow)
        setOpenPopup(true)
    }

    const openInImagePopup = id => {
        const selectedRow = records.find((record) => record.id === id);
        setImageUrl(config.IMAGE_URL + selectedRow.picture)
        setOpenImagePopup(true)
    }

    const handleUploadClick = (id) => () => {
        setSelectedId(id)
        const input = document.getElementById('file-input');

        if (input) {
            input.click();
        }

    };

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0]
        const formData = new FormData();
        formData.append("selectedFile", selectedFile);
        RemnantService.uploadFile(selectedId, formData)
            .then(response => {
                retrieveRemnants();
            })
            .catch(e => {
                alert(e);
            });
    }

    const columns = [
        {
            field: 'picture',
            headerName: '',
            width: 50,
            renderCell: (params) => <img alt="remnant thumbnail" style={{ height: '100%', margin: -16 }} src={config.IMAGE_URL + params.value} />,
        },
        {
            field: 'stock_number',
            headerName: 'Stock #',
            maxWidth: 75,
            valueGetter: getStockNumber,
        },
        { field: 'material_type', headerName: 'Type', flex: 1, maxWidth: 100 },
        { field: 'material_name', headerName: 'Name', flex: 1 },
        { field: 'location', headerName: 'Location', flex: 1 },
        { field: 'serial_number', headerName: 'Serial #', flex: 1 },
        { field: 'lot_number', headerName: 'Lot #', flex: 1 },
        { field: 'width', headerName: 'Width (in)', maxWidth: 100 },
        { field: 'length', headerName: 'Length (in)', maxWidth: 100 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 120,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<ZoomOutMapIcon />}
                        label="View"
                        className="textPrimary"
                        onClick={() => { openInImagePopup(id) }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => { openInPopup(id) }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<UploadIcon />}
                        label="Upload"
                        onClick={handleUploadClick(id)}
                        color="inherit">
                    </GridActionsCellItem>,
                ];
            },
        },
    ];

    return (
        <>
            <StyledPaper>

                <Toolbar>
                    <AddNewButton
                        text="Add New"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => { setOpenPopup(true); setRecordForEdit(null); }}
                    />
                </Toolbar>
                <Box
                    sx={{
                        height: 500,
                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                    }}
                >
                    <input
                        type="file"
                        id="file-input"
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                    />
                    <DataGrid
                        rows={records}
                        columns={columns}
                    />
                </Box>
            </StyledPaper>
            <Popup
                title="Remnant Form"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
            >
                <RemnantForm
                    recordForEdit={recordForEdit}
                    addOrEdit={addOrEdit}
                    materialNames={materialNames} />
            </Popup>
            <Popup
                title="Remnant Image"
                openPopup={openImagePopup}
                setOpenPopup={setOpenImagePopup}
            >
                <RemnantImage imageUrl={imageUrl} />

            </Popup>
        </>
    )
}
