import api from "./api";

const list = () => {
    return api.get('/remnants');
}
const listMaterialNames = () => {
    return api.get('/remnantMaterials');
}

const update = (remnant) => {
    return api.post('/remnant', remnant);
}

const create = (remnant) => {
    return api.put('/remnant', remnant);
}

const uploadFile = (id, data) => {
    return api.post('/uploadRemnantImage?id=' + id, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}


const RemnantService = {
    list,
    update,
    create,
    uploadFile,
    listMaterialNames,
};

export default RemnantService;