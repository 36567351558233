import React from 'react';
import { Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';



export default function Home() {
    const navigate = useNavigate();
    return (
        <Grid sx={{ flexGrow: 1, paddingTop: 10 }} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid sx={{ padding: 1 }}>
                        <Paper sx={{ padding: 1 }}>
                            <ListItemButton onClick={() => navigate("/")}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home" />
                            </ListItemButton>
                        </Paper>
                    </Grid>
                    <Grid sx={{ padding: 1 }}>
                        <Paper sx={{ padding: 1 }}>
                            <ListItemButton onClick={() => navigate("/remnants")}>
                                <ListItemIcon>
                                    <DashboardIcon />
                                </ListItemIcon>
                                <ListItemText primary="Remnants" />
                            </ListItemButton>

                        </Paper>
                    </Grid>

                </Grid>
            </Grid>
        </Grid >
    );
}