import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from '@mui/material';

export default function DatePickerControl(props) {

    const { name, label, value, onChange } = props


    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker disableToolbar variant="inline" inputVariant="outlined" renderInput={(params) => <TextField {...params} />}
                label={label}
                format="MMM/dd/yyyy"
                name={name}
                value={value}
                onChange={date => onChange(convertToDefEventPara(name, date))}

            />
        </LocalizationProvider>
    )
}
