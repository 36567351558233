import * as React from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Home from './home.component';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AccountOptions from './accountOptions.component';
import Remnants from '../pages/Remant';
import NavMenu from './menu.component';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}));

function MainContent(props) {
    return (
        <BrowserRouter>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute">
                    <Toolbar sx={{ pr: '24px' }}>
                        <IconButton edge="start" color="inherit"
                            sx={{ marginRight: '36px' }}>
                            <NavMenu />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            Inventory
                        </Typography>
                        <AccountOptions />
                    </Toolbar>
                </AppBar>
                <Box component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto'
                    }}>
                    <Toolbar />
                    <Container maxWidth="xlg">
                        <Routes>
                            <Route element={<Home />} path="" />
                            <Route element={<Remnants />} path="remnants" />
                        </Routes>
                    </Container>
                </Box>
            </Box>
        </BrowserRouter>
    );
}

export default function Main() {
    return <MainContent />;
}
