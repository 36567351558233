import React, { useState } from 'react'
import { styled } from '@mui/system';
const StyledForm = styled('form')(({ theme }) => ({
    '& .MuiFormControl-root': {
        width: '80%',
        margin: theme.spacing(1)
    }
}));

export function useForm(initialFValues, validateOnChange = false, validate) {


    const [values, setValues] = useState(initialFValues);
    const [errors, setErrors] = useState({});

    const handleInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const handleNumericInputChange = e => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: Number(value)
        })
        if (validateOnChange)
            validate({ [name]: value })
    }

    const resetForm = () => {
        setValues(initialFValues);
        setErrors({})
    }


    return {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleNumericInputChange,
        resetForm

    }
}

export function Form(props) {

    const { children, ...other } = props;
    return (
        <StyledForm autoComplete="off" {...other}>
            {props.children}
        </StyledForm>
    )
}

