import React from 'react'
import { Button } from '@mui/material';
import { styled } from '@mui/system';



const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
    minWidth: 0,
    margin: theme.spacing(0.5),
    backgroundColor: color === 'primary' ? theme.palette.primary.light : theme.palette.secondary.light,
    '& .MuiButton-label': {
        color: color === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
    }
}));

// const useStyles = makeStyles(theme => ({
//     root: {
//         minWidth: 0,
//         margin: theme.spacing(0.5)
//     },
//     secondary: {
//         backgroundColor: theme.palette.secondary.light,
//         '& .MuiButton-label': {
//             color: theme.palette.secondary.main,
//         }
//     },
//     primary: {
//         backgroundColor: theme.palette.primary.light,
//         '& .MuiButton-label': {
//             color: theme.palette.primary.main,
//         }
//     },
// }))

export default function ActionButton(props) {

    const { color, children, onClick } = props;

    return (
        <StyledButton
            color={color}
            onClick={onClick}>
            {children}
        </StyledButton>
    )
}
