import React, { useEffect } from "react";
import SignIn from './components/login.component';
import AuthService from "./services/auth.service";
import TokenService from "./services/token.service";
import Main from './components/main.component'
import EventBus from "./common/EventBus";

export default function App() {
  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    TokenService.removeUser()
  };

  if (TokenService.tokenExpired()) {
    return (
      <SignIn />
    )
  }
  return (
    <Main />
  );
}