import React, { useEffect } from 'react'
import { Grid, Box } from '@mui/material';
import Controls from "../components/controls/Controls";
import { useForm, Form } from '../components/useForm';


const initialFValues = {
    id: 0,
    material_id: 0,
    material_name: '',
    location: '',
    width: 0,
    length: 0,
    serial_number: '',
    stock_number: '',
    lot_number: '',
}

const boxDefault = {
    padding: 2
};

export default function RemnantForm(props) {
    const { addOrEdit, recordForEdit, materialNames } = props


    const validate = (fieldValues = values) => {
        let temp = { ...errors }
        if ('material_id' in fieldValues)
            temp.material_id = fieldValues.material_id ? "" : "This field is required."
        if ('material_name' in fieldValues)
            temp.material_name = fieldValues.material_name ? "" : "This field is required."
        if ('location' in fieldValues)
            temp.location = fieldValues.location ? "" : "This field is required."
        if ('serial_number' in fieldValues)
            temp.serial_number = fieldValues.serial_number ? "" : "This field is required."
        if ('lot_number' in fieldValues)
            temp.lot_number = fieldValues.lot_number ? "" : "This field is required."
        setErrors({
            ...temp
        })

        if (fieldValues === values)
            return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        handleNumericInputChange,
        resetForm
    } = useForm(initialFValues, true, validate);

    const handleSubmit = e => {
        e.preventDefault()
        if (validate()) {
            addOrEdit(values, resetForm);
        }
    }
    useEffect(() => {
        if (recordForEdit != null)
            setValues({
                ...recordForEdit
            })
    }, [recordForEdit])

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.Select
                        name="material_id"
                        label="Type"
                        value={values.material_id}
                        onChange={handleInputChange}
                        options={materialNames}
                        error={errors.material_id}
                    />
                    <Controls.Input
                        label="Name"
                        name="material_name"
                        value={values.material_name}
                        onChange={handleInputChange}
                        error={errors.material_name}
                    />
                    <Controls.Input
                        label="Location"
                        name="location"
                        value={values.location}
                        onChange={handleInputChange}
                        error={errors.location}
                    />

                    <Controls.Input
                        name="serial_number"
                        label="Serial Number"
                        value={values.serial_number}
                        onChange={handleInputChange}
                        error={errors.serial_number}
                    />

                </Grid>
                <Grid item xs={6}>
                    <Controls.Input
                        label="Lot Number"
                        name="lot_number"
                        value={values.lot_number}
                        onChange={handleInputChange}
                        error={errors.lot_number}
                    />
                    <Controls.Input
                        label="Width (inches)"
                        name="width"
                        value={values.width}
                        onChange={handleNumericInputChange}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        error={errors.width}
                    />
                    <Controls.Input
                        label="Length (inches)"
                        name="length"
                        value={values.length}
                        onChange={handleNumericInputChange}
                        type="number"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        error={errors.length}
                    />
                </Grid>
            </Grid>
            <Box
                m={1}
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={boxDefault}
            >
                <Controls.Button
                    type="submit"
                    text="Submit" />
                <Controls.Button
                    text="Reset"
                    color="inherit"
                    onClick={resetForm} />
            </Box>
        </Form >
    )
}
