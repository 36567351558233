import React from 'react'
import { Paper } from '@mui/material';

export default function RemnantImage(props) {
    const { imageUrl } = props

    return (
        <Paper>
            <img
                src={`${imageUrl}`}
                width="100%" height="100%"
            />
        </Paper>
    )
}
