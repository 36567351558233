import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';
import Controls from "./controls/Controls";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const StyledDialog = styled(Dialog)(({ theme }) => ({
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
}));
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    paddingRight: '0px'
}));
export default function Popup(props) {

    const { title, children, openPopup, setOpenPopup } = props;

    return (
        <StyledDialog open={openPopup} maxWidth="md">
            <StyledDialogTitle>
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <Controls.ActionButton
                        color="secondary"
                        onClick={() => { setOpenPopup(false) }}>
                        <CloseIcon />
                    </Controls.ActionButton>
                </div>
            </StyledDialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </StyledDialog>
    )
}
